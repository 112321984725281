import React, { useEffect, useState } from 'react';
import CategoriesList from './CategoriesList.tsx';
import { fetchCategories } from './api/api';

interface SubCategory {
  id: number;
  title: string;
  imgUrl: string;
}

interface Category {
  id: number;
  title: string;
  imgUrl: string;
  subCategories: SubCategory[];
  priority: number;
}

const CategoriesContainer = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getCategories = async () => {
      try {
        console.log('Fetching categories...');
        const data = await fetchCategories();
        console.log('Categories fetched:', data); // Log the data
        if (Array.isArray(data)) {
          setCategories(data);
        } else {
          console.error('Expected an array but got:', data);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
        setError(error.message);
      }
    };

    getCategories();
  }, []);

  const handleCategoryClick = (category: Category) => {
    console.log('Category clicked:', category);
    setIsActive(!isActive);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <CategoriesList
        categories={categories}
        onCategoryClick={handleCategoryClick}
        isActive={isActive}
      />
    </div>
  );
};

export default CategoriesContainer;
