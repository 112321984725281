import React, { useState } from 'react';
import { fetchBusinessById, updateBusinessById, sendVerificationCode, verifyCode } from './api/api';
import { TextField, Button, Typography, Container, Box, CircularProgress, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './EditBusinessForm.css';

interface Business {
  _id: string;
  mainPhoto: string;
  ownerName: string;
  businessName: string;
  phoneNumber: string;
  location: string;
  description: string;
  photos: string[];
  openingHours: OpeningHour[];
  subCategoryTitle: string;
  isFullTime: boolean;
  instagramURL: string;
  facebookURL: string;
  wazeURL: string;
  whatsApp: string;
  byAppointment: boolean;
}

interface OpeningHour {
  day: string;
  hours: string;
  breaks?: { start: string, end: string }[];
}

const theme = createTheme();

const EditBusinessForm: React.FC = () => {
  const [businessId, setBusinessId] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [businessDetails, setBusinessDetails] = useState<Business | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [step, setStep] = useState<'enter-details' | 'verify-code' | 'edit-business'>('enter-details');

  const handleInitialSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = await fetchBusinessById(businessId);
      if (data.phoneNumber === phoneNumber) {
        await sendVerificationCode(phoneNumber);
        setStep('verify-code');
      } else {
        setError('Phone number does not match our records.');
      }
    } catch (error) {
      setError('Failed to fetch business details');
    } finally {
      setLoading(false);
    }
  };

  const handleVerifySubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await verifyCode(phoneNumber, verificationCode);
      if (response.success) {
        const data = await fetchBusinessById(businessId);
        setBusinessDetails(data);
        setStep('edit-business');
      } else {
        setError('Invalid verification code');
      }
    } catch (error) {
      setError('Failed to verify code');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setBusinessDetails(prevState => prevState ? { ...prevState, [name]: value } : null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (businessDetails) {
        await updateBusinessById(businessDetails._id, businessDetails);
        alert('Business details updated successfully');
      }
    } catch (error) {
      setError('Failed to update business details');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            {step === 'enter-details' && 'Enter Business Details'}
            {step === 'verify-code' && 'Verify Code'}
            {step === 'edit-business' && 'Edit Business'}
          </Typography>

          {loading && <CircularProgress />}

          {error && <Typography color="error">{error}</Typography>}

          {step === 'enter-details' && (
            <Box component="form" onSubmit={handleInitialSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="businessId"
                label="Business ID"
                name="businessId"
                autoFocus
                value={businessId}
                onChange={(e) => setBusinessId(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="phoneNumber"
                label="Phone Number"
                type="tel"
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </Button>
            </Box>
          )}

          {step === 'verify-code' && (
            <Box component="form" onSubmit={handleVerifySubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="verificationCode"
                label="Verification Code"
                name="verificationCode"
                autoFocus
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Verify
              </Button>
            </Box>
          )}

          {step === 'edit-business' && businessDetails && (
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="businessName"
                label="Business Name"
                name="businessName"
                value={businessDetails.businessName}
                onChange={handleChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="ownerName"
                label="Owner Name"
                name="ownerName"
                value={businessDetails.ownerName}
                onChange={handleChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phoneNumber"
                label="Phone Number"
                name="phoneNumber"
                value={businessDetails.phoneNumber}
                onChange={handleChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="location"
                label="Location"
                name="location"
                value={businessDetails.location}
                onChange={handleChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="description"
                label="Description"
                name="description"
                value={businessDetails.description}
                onChange={handleChange}
                multiline
                rows={4}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="subCategoryTitle"
                label="Subcategory Title"
                name="subCategoryTitle"
                value={businessDetails.subCategoryTitle}
                onChange={handleChange}
              />
              <TextField
                margin="normal"
                fullWidth
                id="instagramURL"
                label="Instagram URL"
                name="instagramURL"
                value={businessDetails.instagramURL}
                onChange={handleChange}
              />
              <TextField
                margin="normal"
                fullWidth
                id="facebookURL"
                label="Facebook URL"
                name="facebookURL"
                value={businessDetails.facebookURL}
                onChange={handleChange}
              />
              <TextField
                margin="normal"
                fullWidth
                id="wazeURL"
                label="Waze URL"
                name="wazeURL"
                value={businessDetails.wazeURL}
                onChange={handleChange}
              />
              <TextField
                margin="normal"
                fullWidth
                id="whatsApp"
                label="WhatsApp"
                name="whatsApp"
                value={businessDetails.whatsApp}
                onChange={handleChange}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <Typography>Is Full Time</Typography>
                <input
                  type="checkbox"
                  name="isFullTime"
                  checked={businessDetails.isFullTime}
                  onChange={(e) => setBusinessDetails((prevState) => prevState ? { ...prevState, isFullTime: e.target.checked } : null)}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <Typography>By Appointment</Typography>
                <input
                  type="checkbox"
                  name="byAppointment"
                  checked={businessDetails.byAppointment}
                  onChange={(e) => setBusinessDetails((prevState) => prevState ? { ...prevState, byAppointment: e.target.checked } : null)}
                />
              </Box>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Save Changes
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default EditBusinessForm;
