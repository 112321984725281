import React from 'react';
import './AboutUs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import haytamPhoto from './img/About Us/About - 1.webp';
import jeriesPhoto from './img/About Us/About - 2.webp';


const AboutUs = () => {
  const whatsappNumber = '+972549576132';

  return (
    <div className="container">
      <div className="content-wrapper">
        <h1>من نحن</h1>
        <p className="intro-text">
          مرحبًا بكم في موقع ibifi، وجهتكم الشاملة للعثور على جميع الأعمال ومقدمي الخدمات في قرية عبلّين. سواء كنتم تبحثون عن مهنيين محليين أو أصحاب مصالح ومقدمّي خدمات مختلفة، منصتنا تربطكم بأصحاب هذه الخدمات التي تحتاجونها بكل سهولة. تأسس موقع ibifi في عام 2024 بهدف تعزيز الوصول والظهور لكافة الأعمال في قريتنا.
        </p>
        <h2>رؤيتنا</h2>
        <p>
          نسعى من خلال هذا الموقع إلى تسهيل عملية الوصول إلى مختلف المصالح والخدمات في القرية، مما يسهم في تعزيز الاقتصاد المحلي ودعمه.
        </p>
        <div className="team-section">
          <div className="team-member">
            <img src={haytamPhoto} className="member-photo" alt="Haitham Khalil" />
            <div className="member-info">
              <h2>Haitham Khalil</h2>
              <p className="role">CEO & Co-Founder</p>
              <p className="company">مهندس برمجيات في شركة</p>
              <p>Pagaya: AI Lending Network</p>
            </div>
          </div>
          <div className="team-member">
            <img src={jeriesPhoto} className="member-photo" alt="Jeries Shomaly" />
            <div className="member-info">
              <h2>Jeries Shomaly</h2>
              <p className="role">CEO & Co-Founder</p>
              <p className="company">مهندس برمجيات في شركة</p>
              <p>Comrax | Web Innovation & Digital Transformation</p>
            </div>
          </div>
        </div>
        <div className="cta-section">
          <h2>تواصل معنا</h2>
          <p>للمزيد من المعلومات، لا تترددوا في التواصل معنا!</p>
          <div className="contact-info">
            <a href="mailto:ibifi2024@gmail.com" className="contact-link">
              <FontAwesomeIcon icon={faEnvelope} /> ibifi2024@gmail.com
            </a>
            <a href="https://www.facebook.com/profile.php?id=61559882064052" className="contact-link" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} /> صفحة الفيسبوك
            </a>
            <a href={`https://wa.me/${whatsappNumber}`} className="contact-link" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
