import React, { useState, useEffect } from 'react';
import CategoriesList from './CategoriesList.tsx';
import { fetchCategories } from './api/api.js';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

interface SubCategory {
  id: number;
  title: string;
  imgUrl: string;
}

interface Category {
  id: number;
  title: string;
  imgUrl: string;
  subCategories: SubCategory[];
  priority: number;
}

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState<Category[]>([]);
  const [status, setStatus] = useState<{ error: string | null; isLoading: boolean }>({
    error: null,
    isLoading: true,
  });

  useEffect(() => {
    const fetchCategoriesData = async () => {
      setStatus({ error: null, isLoading: true });
      try {
        const data = await fetchCategories();
        setCategories(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error('Failed to fetch categories', error);
        setStatus({ error: 'Failed to load categories.', isLoading: false });
      } finally {
        setStatus((prevState) => ({ ...prevState, isLoading: false }));
      }
    };
    fetchCategoriesData();
  }, []);

  const handleCategoryClick = (category: Category) => {
    // Google Analytics event tracking
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'click', {
        event_category: 'Category',
        event_label: category.title,
      });
    }

    const path = category.subCategories.length === 0 
      ? `/categories/${category.id}/businesses`
      : `/categories/${category.id}/subcategories`;
    const state = category.subCategories.length === 0 
      ? { subCategoryTitle: category.imgUrl, titleToShow: category.title, isCategory: true }
      : { category };
    navigate(path, { state });
  };

  if (status.isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <ClipLoader color="#123abc" loading={true} size={150} />
      </div>
    );
  }

  if (status.error) return <div>Error: {status.error}</div>;

  return (
    <div className="Home">
      <CategoriesList categories={categories} onCategoryClick={handleCategoryClick} isActive={false} />
    </div>
  );
};

export default Home;
