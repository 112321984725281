import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ color: 'black', padding: '20px' }}>
      <h1 style={{ color: 'black'}}>Privacy Policy</h1>
      <p style={{ color: 'black'}}>At ibifi.io, we value your privacy. This Privacy Policy explains how we collect, use, and share information about you when you use our mobile application ("App") and related services (collectively, "Services"). By using our Services, you agree to the collection and use of information in accordance with this policy.</p>
      
      <h2 style={{ color: 'black'}}>Information We Collect</h2>
      <p style={{ color: 'black'}}>We may collect the following types of information:</p>
      <ul style={{ color: 'black'}}>
          <li><strong>Personal Information:</strong> Information that can be used to identify you, such as your name, email address, phone number, and location.</li>
          <li><strong>Device Information:</strong> Information about your device, including IP address, operating system, device model, and app usage data.</li>
          <li><strong>Usage Data:</strong> Information about how you use our App, including pages visited, features used, and actions taken within the App.</li>
          <li><strong>Location Data:</strong> Information about your location, which may be used to provide location-based services.</li>
      </ul >
      
      <h2 style={{ color: 'black'}}>How We Use Your Information</h2>
      <p style={{ color: 'black'}}>We use the information we collect for various purposes, including:</p>
      <ul style={{ color: 'black'}}> 
          <li>To provide, operate, and maintain our App.</li>
          <li>To improve, personalize, and expand our App.</li>
          <li>To understand and analyze how you use our App.</li>
          <li>To communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the App, and for marketing and promotional purposes.</li>
          <li>To process your transactions and manage your orders.</li>
          <li>To find and prevent fraud.</li>
          <li>To comply with legal obligations.</li>
      </ul>
      
      <h2 style={{ color: 'black'}}>Sharing Your Information</h2>
      <p style={{ color: 'black'}}>We may share your information with:</p>
      <ul>
          <li><strong>Service Providers:</strong> We may share your information with third-party service providers to help us operate our App and provide our Services.</li>
          <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
      </ul>
      
      <h2 style={{ color: 'black'}}>Security</h2>
      <p style={{ color: 'black'}}>We take reasonable measures to help protect your information from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction.</p>
      
      <h2 style={{ color: 'black'}}>Children's Privacy</h2>
      <p style={{ color: 'black'}}>Our Services are not intended for children under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us. If we become aware that we have collected personal information from a child under age 13 without verification of parental consent, we take steps to remove that information from our servers.</p>
      
      <h2 style={{ color: 'black'}}>Categories and Business Listings</h2>
      <p style={{ color: 'black'}}>Our App categorizes businesses in your city by types such as food, hand-work, etc. Each main category has subcategories (e.g., restaurants under food). When you select a category like sweets, our App will display all sweet shops in your city. You can then view detailed information about each business, including their details, location, phone number, working hours, and other relevant information.</p>
      
      <h2 style={{ color: 'black'}}>Contact Us</h2>
      <p style={{ color: 'black'}}>If you have any questions about this Privacy Policy, please contact us:</p>
      <p style={{ color: 'black'}}>Email: ibifi2024@gmail.com</p>
    </div>
  );
}

export default PrivacyPolicy;
