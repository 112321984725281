import React, { useEffect, useState } from 'react';
import './BusinessList.css';
import { FaCalendarCheck, FaClock, FaFacebookF, FaInstagram, FaPhone, FaTimes, FaWhatsapp } from 'react-icons/fa';
import { SiWaze } from "react-icons/si";
import moment from 'moment';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { fetchBusinessBySubCategoryTitle, fetchCategoryById } from './api/api.js';
import { ClipLoader } from 'react-spinners';
import dummyImage from './img/ibifilogo.png';

interface Business {
  _id: string;
  mainPhoto: string;
  ownerName: string;
  businessName: string;
  phoneNumber: string;
  openingHours: OpeningHour[];
  subCategoryTitle: string;
  isFullTime: boolean;
  byAppointment: boolean;
  instagramURL: string;
  facebookURL: string;
  wazeURL: string;
  whatsApp: string;
}

interface OpeningHour {
  day: string;
  hours: string;
}

const BusinessList: React.FC = () => {
  const navigate = useNavigate();
  const { categoryId, subCategoryId } = useParams<{ categoryId: string, subCategoryId?: string }>();
  const location = useLocation();
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [loading, setLoading] = useState(true);
  const [titleToShow, setTitleToShow] = useState<string>('');

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        let titleToFetch = '';
        if (location.state && location.state.subCategoryTitle) {
          titleToFetch = location.state.subCategoryTitle;
          setTitleToShow(location.state.titleToShow);
        } else {
          const category = await fetchCategoryById(categoryId!);
          if (subCategoryId) {
            const subCategory = category.subCategories.find((sub: any) => sub.id === parseInt(subCategoryId));
            if (subCategory) {
              titleToFetch = subCategory.title;
              setTitleToShow(subCategory.title);
            }
          } else {
            titleToFetch = category.imgUrl;
            setTitleToShow(category.imgUrl);
          }
        }

        if (titleToFetch) {
          const data = await fetchBusinessBySubCategoryTitle(titleToFetch);
          
          // Separate businesses based on status
          const openBusinesses = data.filter((business: Business) => isOpenNow(business.openingHours, business.isFullTime) && !business.byAppointment);
          const fullTimeBusinesses = data.filter((business: Business) => business.isFullTime && !isOpenNow(business.openingHours, business.isFullTime));
          const bookingBusinesses = data.filter((business: Business) => business.byAppointment);
          const closedBusinesses = data.filter((business: Business) => !isOpenNow(business.openingHours, business.isFullTime) && !business.isFullTime && !business.byAppointment);
  
          // Shuffle open businesses
          const shuffledOpenBusinesses = shuffleArray(openBusinesses);
  
          // Combine them in the desired order
          const sortedBusinesses = [
            ...shuffledOpenBusinesses,
            ...fullTimeBusinesses,
            ...bookingBusinesses,
            ...closedBusinesses
          ];
  
          setBusinesses(sortedBusinesses);
        }
      } catch (error) {
        console.error("Failed to fetch businesses", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchBusinesses();
  }, [categoryId, subCategoryId, location.state]);

  const mapDayArabicToEnglish = (arabicDay: string) => {
    const dayMapping: { [key: string]: string } = {
      'الأحد': 'Sunday',
      'الاثنين': 'Monday',
      'الثلاثاء': 'Tuesday',
      'الأربعاء': 'Wednesday',
      'الخميس': 'Thursday',
      'الجمعة': 'Friday',
      'السبت': 'Saturday'
    };
    return dayMapping[arabicDay] || arabicDay; // Return original if no match found
  };

  const isOpenNow = (openingHours: OpeningHour[], isFullTime: boolean) => {
    if (isFullTime) return true;
  
    const currentDay = moment().format('dddd'); // Gets the day in English
    const currentTime = moment();
    const todayHours = openingHours.find(day => mapDayArabicToEnglish(day.day) === currentDay);
    const tomorrowHours = openingHours.find(day => mapDayArabicToEnglish(day.day) === moment().add(1, 'day').format('dddd'));
  
    if (!todayHours || todayHours.hours === 'Off') return false;
  
    const [openTime, closeTime] = todayHours.hours.split(' - ');
    const open = moment(openTime, 'HH:mm');
    let close = moment(closeTime, 'HH:mm');
  
    // Adjust for closing at or after midnight
    if (closeTime === '00:00') {
      close = moment('24:00', 'HH:mm'); // Consider '24:00' to mean end of the current day
    } else if (close.isBefore(open)) {
      // If closing time is after midnight, add a day to closing time
      close.add(1, 'day');
    }
  
    // Check if current time is between open and close times
    if (currentTime.isBetween(open, close, undefined, '[)')) {
      return true;
    } else if (close.isAfter(moment().endOf('day'))) {
      // Check if it is open into the next day
      const [nextOpenTime, nextCloseTime] = tomorrowHours?.hours.split(' - ') || ['', ''];
      const nextOpen = moment(nextOpenTime, 'HH:mm').add(1, 'day');
      const nextClose = moment(nextCloseTime, 'HH:mm').add(1, 'day');
  
      return currentTime.isBetween(open, nextClose, undefined, '[)');
    }
  
    return false;
  };

  const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handleBusinessClick = (businessId: string, businessName?:string) => {
    // Google Analytics event tracking
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'click', {
        event_category: 'Business',
        event_label: businessName,
      });
    }
    navigate(`/businesses/${businessId}`);
  };

  const dummyBusiness: Business = {
    _id: 'dummy',
    mainPhoto: dummyImage, // Replace with your image URL
    ownerName: 'اسم صاحب المصلحة',
    businessName: 'اسم المصلحة',
    phoneNumber: '123456789',
    openingHours: [],
    subCategoryTitle: '',
    isFullTime: false,
    byAppointment: false,
    instagramURL: '',
    facebookURL: '',
    wazeURL: '',
    whatsApp: ''
  };

  return (
    <div style={{ marginTop: '5vh' }}>
      <div className="business-list-container-new" style={{ margin: '0 auto', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        {loading ? (
          <div style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', marginTop: '20px' }}>
            <ClipLoader color="#123abc" loading={true} size={150} />
          </div>
        ) : (
          businesses.length === 0 ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh', fontSize: '20px', fontWeight: 'bold', flexDirection: 'column' }}>
              <div> سجّل معنا لتكون الأول في زاوية {titleToShow} </div>
              <div className="business-preview">
                <img src={dummyBusiness.mainPhoto} className="business-logo-preview_dummy" alt="Business Logo" />
                <div className="business-info-preview">
                  <p className="business-name-List">{dummyBusiness.businessName}</p>
                  <p className="business-owner-List">{dummyBusiness.ownerName}</p>
                  <a href={`tel:${dummyBusiness.phoneNumber}`} className="phone-number">
                    {dummyBusiness.phoneNumber} <FaPhone />
                  </a>
                </div>
              </div>
            </div>
          ) : (
            businesses.map((business) => (
              <div key={business._id} className="business-preview">
                <img onClick={() => handleBusinessClick(business._id, business.businessName)} src={business.mainPhoto} className="business-logo-preview" alt="Business Logo" />
                <div onClick={() => handleBusinessClick(business._id,business.businessName)} className="business-info-preview">
                  <p className="business-name-List">{business.businessName}</p>
                  <p className="business-owner-List">{business.ownerName}</p>
                  <a href={`tel:${business.phoneNumber}`} className="phone-number">
                    {business.phoneNumber} <FaPhone />
                  </a>
                  <div className="info-row">
                    {business.byAppointment ? (
                      <div className="businesses-status-container by-appointment-status">
                        <FaCalendarCheck style={{color:"#284d68"}} className="icon Appointment" />
                        {'بالتنسيق المسبق'}
                      </div>
                    ) : (
                      <div className={isOpenNow(business.openingHours, business.isFullTime) ? 'businesses-status-container open-status' : 'businesses-status-container closed-status'}>
                        {isOpenNow(business.openingHours, business.isFullTime) ? <FaClock className="icon" /> : <FaTimes className="icon" />}
                        {isOpenNow(business.openingHours, business.isFullTime) ? 'شغّال' : 'مغلق'}
                      </div>
                    )}
                  </div>
                </div>
                <div className="social-links">
                  {business.facebookURL && (
                    <div className="social-icon facebook">
                      <a href={`${business.facebookURL}`} target="_blank" rel="noopener noreferrer">
                        <FaFacebookF />
                      </a>
                    </div>
                  )}
                  {business.instagramURL && (
                    <div className="social-icon instagram">
                      <a href={`${business.instagramURL}`} target="_blank" rel="noopener noreferrer">
                        <FaInstagram />
                      </a>
                    </div>
                  )}
                  {business.whatsApp && (
                    <div className="social-icon whatsapp">
                      <a href={`https://wa.me/${business.whatsApp}`} target="_blank" rel="noopener noreferrer" className="social-link">
                        <FaWhatsapp />
                      </a>
                    </div>
                  )}
                  {business.wazeURL && (
                    <div className="social-icon waze">
                      <a href={`${business.wazeURL}`} target="_blank" rel="noopener noreferrer" className="social-link">
                        <SiWaze />
                      </a>
                    </div>
                  )}
                </div>
              </div>
            ))
          )
        )}
      </div>
    </div>
  );
};

export default BusinessList;
