import React, { useState, useEffect } from 'react';
import { FaFacebookF, FaInstagram, FaWhatsapp, FaShareAlt } from 'react-icons/fa';
import { SiWaze } from "react-icons/si";
import { useSwipeable } from 'react-swipeable';
import './BusinessCard.css';
import { useParams } from 'react-router-dom';
import { fetchBusinessById } from './api/api';
import { ClipLoader } from 'react-spinners';

interface Business {
  _id: string;
  mainPhoto: string;
  ownerName: string;
  businessName: string;
  phoneNumber: string;
  location: string;
  description: string;
  photos: string[];
  openingHours: OpeningHour[];
  subCategoryTitle: string;
  isFullTime: boolean;
  instagramURL: string;
  facebookURL: string;
  wazeURL: string;
  whatsApp: string;
  byAppointment: boolean;
}

interface OpeningHour {
  day: string;
  hours: string;
  breaks?: { start: string, end: string }[];
}

const BusinessCard: React.FC = () => {
  const { businessId } = useParams<{ businessId: string }>();
  const [businessDetails, setBusinessDetails] = useState<Business | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [animationClass, setAnimationClass] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const data = await fetchBusinessById(businessId!);
        console.log("Fetched business details:", data);
        setBusinessDetails(data);
      } catch (error) {
        console.error('Failed to fetch business details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [businessId]);

  const handleShareClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: businessDetails?.businessName,
          text: `Check out ${businessDetails?.businessName} on our platform!`,
          url: window.location.href
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert('Share not supported on this browser');
    }
  };

  const daysOff = businessDetails?.openingHours.filter(hour => hour.hours === 'Off') || [];
  const filteredOpeningHours = businessDetails?.openingHours.filter(hour => hour.hours !== ' - ' && hour.hours !== 'Off') || [];

  const formatWorkingHours = (hours: string, breaks?: { start: string, end: string }[]) => {
    if (!breaks || breaks.length === 0) {
      return [hours];
    }

    const segments: string[] = [];
    const [start, end] = hours.split(' - ');

    let lastEnd = start;
    breaks.forEach(breakTime => {
      segments.push(`${lastEnd} - ${breakTime.start}`);
      lastEnd = breakTime.end;
    });

    segments.push(`${lastEnd} - ${end}`);

    return segments;
  };

  const handleImageClick = (index: number) => {
    setCurrentImageIndex(index);
    setAnimationClass('fade-in');
    setModalOpen(true);
    document.body.classList.add('no-scroll'); // Add this line
  };

  const closeModal = () => {
    setAnimationClass('fade-out');
    setTimeout(() => {
      setModalOpen(false);
      document.body.classList.remove('no-scroll'); // Add this line
    }, 600);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => setCurrentImageIndex((prevIndex) => (prevIndex + 1) % (businessDetails?.photos.length || 1)),
    onSwipedRight: () => setCurrentImageIndex((prevIndex) => (prevIndex - 1 + (businessDetails?.photos.length || 1)) % (businessDetails?.photos.length || 1))
  });

  const getCurrentDay = () => {
    const days = ["الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"];
    const today = new Date().getDay();
    return days[today];
  };

  const currentDay = getCurrentDay();

  if (loading) {
    return (
      <div style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', marginTop: '20px' }}>
        <ClipLoader color="#123abc" loading={true} size={150} />
      </div>
    );
  }

  return (
    <div>
      {businessDetails && (
        <div className="business-card-container">
          <div className="header">
            <img src={businessDetails.mainPhoto} className="business-logo" alt="Business Logo" />
            <div className="business-info">
              <h1 style={{ color: 'white' }} className='business-name'>{businessDetails.businessName}</h1>
              <p style={{ color: 'white' }} className='business-owner'>{businessDetails.ownerName}</p>
            </div>
          </div>

          <div className="business-card-content">
            <p className='business-phone-number'><strong>رقم الهاتف:</strong> {businessDetails.phoneNumber}</p>
            <p className='business-location'><strong>الموقع:</strong> {businessDetails.location}</p>
            <p className='business-description'>{businessDetails.description}</p>
          </div>

          <div className="business-links">
            {businessDetails.facebookURL && (
              <div className="social-icon facebook">
                <a href={businessDetails.facebookURL} target="_blank" rel="noopener noreferrer">
                  <FaFacebookF />
                </a>
              </div>
            )}
            {businessDetails.instagramURL && (
              <div className="social-icon instagram">
                <a href={businessDetails.instagramURL} target="_blank" rel="noopener noreferrer">
                  <FaInstagram />
                </a>
              </div>
            )}
            {businessDetails.whatsApp && (
              <div className="social-icon whatsapp">
                <a href={`https://wa.me/${businessDetails.whatsApp}`} target="_blank" rel="noopener noreferrer">
                  <FaWhatsapp />
                </a>
              </div>
            )}
            {businessDetails.wazeURL && (
              <div className="social-icon waze">
                <a href={businessDetails.wazeURL} target="_blank" rel="noopener noreferrer">
                  <SiWaze />
                </a>
              </div>
            )}
            <div className="social-icon facebook" onClick={handleShareClick} style={{ cursor: 'pointer', background: 'black' }}>
              <FaShareAlt />
            </div>
          </div>

          <div className="image-grid">
            {businessDetails.photos.map((photo, index) => (
              <img key={index} src={photo} alt={`Business Content ${index + 1}`} className="grid-image" onClick={() => handleImageClick(index)} />
            ))}
          </div>

          <div className="business-working-days">
            <h3>أوقات العمل</h3>
            {businessDetails.isFullTime ? (
              daysOff.length > 0 ? (
                <div>
                  <p style={{color:'black'}}>يعمل على مدار الساعة ولكن مغلق في الأيام التالية</p>
                  {daysOff.map((entry, index) => (
                    <div key={index} className="working-day-row">
                      <p className='business-hour'><strong>{entry.day}</strong></p>
                    </div>
                  ))}
                </div>
              ) : (
                <p style={{color:'black'}}>متاح في جميع الأوقات وأيام الأسبوع - 7\24</p>
              )
            ) : (
              <table className="opening-hours-table">
  <tbody>
    {filteredOpeningHours.map((entry, index) => (
      <tr key={index} style={{borderRadius: "1000px"}} className={`working-day-row ${entry.day === currentDay ? 'highlight' : ''}`}>
        <td style={{borderTopLeftRadius: "15px"}}>
          {formatWorkingHours(entry.hours, entry.breaks).map((segment, i) => (
            <p key={i} className='business-hour'>{segment}</p>
          ))}
        </td>
        <td style={{borderBottomRightRadius: "15px"}}>
          <p className='business-hour'><strong>{entry.day}</strong></p>
        </td>
      </tr>
    ))}
  </tbody>
</table>


            )}
            {businessDetails.byAppointment && <p style={{color:'black'}}>بالتنسيق المسبق</p>}
          </div>

          {modalOpen && (
            <div className={`modal ${animationClass}`} onClick={closeModal}>
              <div {...swipeHandlers}>
                <img src={businessDetails.photos[currentImageIndex]} alt="Modal Content" className="modal-image" />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BusinessCard;
