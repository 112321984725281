import React, { useState, useEffect } from 'react';
import './photo-grid.css';
import './index.d.ts';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { fetchCategoryById } from './api/api';
import { ClipLoader } from 'react-spinners';

interface SubCategory {
  id: number;
  title: string;
  imgUrl?: string;
}

interface Category {
  id: number;
  title: string;
  imgUrl?: string;
  subCategories: SubCategory[];
  priority: number;
}

const SubCategoriesList: React.FC = () => {
  const navigate = useNavigate();
  const { categoryId } = useParams<{ categoryId: string }>();
  const location = useLocation();
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubCategoriesData = async () => {
      try {
        let category: Category;
        if (location.state && location.state.category) {
          category = location.state.category;
        } else {
          category = await fetchCategoryById(categoryId!);
        }
        setSubCategories(category.subCategories);
      } catch (error) {
        console.error('Failed to fetch subcategories:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchSubCategoriesData();
  }, [categoryId, location.state]);

  const handleSubCategoryClick = (subCategory: SubCategory) => {
    // Google Analytics event tracking
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'click', {
        event_category: 'SubCategory',
        event_label: subCategory.title,
      });
    }

    navigate(`/categories/${categoryId}/subcategories/${subCategory.id}/businesses`, { state: { subCategoryTitle: subCategory.title, titleToShow: subCategory.title } });
  };

  if (loading) {
    return (
      <div style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', marginTop: '20px' }}>
        <ClipLoader color="#123abc" loading={true} size={150} />
      </div>
    );
  }

  if (!subCategories || subCategories.length === 0) {
    return <div>لا يوجد معلومات متوفرة</div>;
  }

  return (
    <section className='photo-grid'>
      {subCategories.map((subCategory) => {
        const imageUrl = subCategory.imgUrl || '';
        return (
          <div
            key={subCategory.id}
            className="card"
            style={{ backgroundImage: `url(${imageUrl})` }}
            onClick={() => handleSubCategoryClick(subCategory)}
          >
            <div className="card-title">
              <span style={{ padding: '2px', fontSize: '1.5rem', fontWeight: 580, color: 'white' }}>
                {subCategory.title}
              </span>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default SubCategoriesList;
