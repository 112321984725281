import React, { useEffect } from 'react';
import './photo-grid.css';
import { images } from './images.ts';
import './index.d.ts';

interface SubCategory {
  id: number;
  title: string;
  imgUrl: string;
}

interface Category {
  id: number;
  title: string;
  imgUrl: string;
  subCategories: SubCategory[];
  priority: number;
}

interface CategoriesListProps {
  onCategoryClick: (category: Category) => void;
  categories: Category[];
  isActive?: boolean;
}

const loadImage = (image: string) => {
  const img = new Image();
  img.src = image;
};

const CategoriesList: React.FC<CategoriesListProps> = ({ categories, onCategoryClick, isActive }) => {
  useEffect(() => {
    categories.forEach((category) => {
      const imageUrl = images[category.imgUrl];
      if (imageUrl) {
        loadImage(imageUrl);
      } else {
        console.warn(`Image URL not found for category: ${category.title}`);
      }
    });
  }, [categories]);

  // Sort categories by priority
  const sortedCategories = [...categories].sort((a, b) => a.priority - b.priority);

  return (
    <section className='photo-grid'>
      {Array.isArray(sortedCategories) && sortedCategories.length > 0 ? (
        sortedCategories.map((category) => {
          const imageUrl = images[category.imgUrl];
          return (
            <div
              key={category.id}
              className={`card ${isActive ? 'active' : ''}`}
              style={{ backgroundImage: `url(${imageUrl || 'default-image-url'})` }}
              onClick={() => onCategoryClick(category)}
            >
              <div className="card-title">{category.title}</div>
            </div>
          );
        })
      ) : (
        <p>No categories found.</p>
      )}
    </section>
  );
};

export default React.memo(CategoriesList);
