import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './navbar.css';
import logo from './img/ibifilogo.png';

const Navbar = () => {
  return (
    <nav className="navbar">
      <a href="/" className="navbar-logo-link">
        <img src={logo} alt="logo" className="navbar-logo" />
      </a>

      <div className="navbar-links">
        <a
          href="https://wa.me/972549576132"
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-icon"
          aria-label="Chat on WhatsApp"
        >
          <FontAwesomeIcon icon={faWhatsapp} size="1x" />
        </a>
        {/* <span className="navbar-separator">|</span>
        <a href="/aboutUs">عن الموقع</a> */}

        <span className="navbar-separator">|</span>
        <a href="/">الرئيسيّة</a>

      </div>
    </nav>
  );
};

export default Navbar;
