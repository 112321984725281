import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './Navbar.tsx';
import Home from './Home.tsx';
import SubCategoriesList from './SubCategoriesList.tsx';
import BusinessList from './BusinessList.tsx';
import BusinessCard from './BusinessCard.tsx';
import AboutUs from './AboutUs.tsx';
import CategoriesContainer from './CategoriesContainer.tsx';
import EditBusinessForm from './EditBusinessForm.tsx';

import './App.css';
import './firebase';
import PrivacyPolicy from './PrivacyPolicy.tsx';

const App = () => {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="*" element={<Navigate replace to="/" />} />
          <Route path="/" element={<Home />} />
          <Route path="/categories" element={<CategoriesContainer />} />
          <Route path="/categories/:categoryId/subcategories" element={<SubCategoriesList />} />
          <Route path="/categories/:categoryId/businesses" element={<BusinessList />} />
          <Route path="/categories/:categoryId/subcategories/:subCategoryId/businesses" element={<BusinessList />} />
          <Route path="/businesses/:businessId" element={<BusinessCard />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/EditBusinessForm" element={<EditBusinessForm />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />


        </Routes>
      </div>
    </Router>
  );
};

export default App;
