// Import each image and then export as a single object
import Food from './img/Food.webp';
import SuperMarkets from './img/supermarket_grocery_section.webp';
import Clothing from './img/clothing_section.webp';
import Architecture from './img/architecture_section.webp';
import Pharmacy from './img/pharmacy_section.webp';
import Construction from './img/construction_section.webp';
import ElectricStore from './img/electricStore_section.webp';
import Pet from './img/pet_section.webp';
import Sweets from './img/sweets_section.webp';
import Gym from './img/gymFitness_section.webp';
import Workers from './img/workers_section.webp';
import Cars from './img/cars_section.webp';
import HairStyle from './img/HairStyle.webp';
import Bakery from './img/Bakery.webp';
import Education from './img/Education.webp';
import MobilePc from './img/MobilePc.webp';
import Makeup from './img/Makeup.webp';
import Art from './img/Art.webp';
import Offices from './img/Offices.webp';
import JewerlyAccessories from './img/JewerlyAccessories.webp';
import FlowersPlants from './img/FlowersPlants.webp';
import Clinic from './img/Clinic.webp';
import Weddings from './img/Weddings.webp';
import Library from './img/Library.webp';
import Hookah from './img/Hookah.webp';
import TikTak from './img/TikTak.webp';
import SkinCare from './img/SkinCare.webp';
import VegetablesFruits from './img/VegetablesFruits.webp';
import CamerasAndSatellites from './img/CamerasAndSatellites.webp';
import Massage from './img/Massage.webp';





export const images = {
    Food,
    SuperMarkets,
    Clothing,
    Architecture,
    Pharmacy,
    Construction,
    ElectricStore,
    Pet,
    Sweets,
    Gym,
    Workers,
    Cars,
    HairStyle,
    Bakery,
    Education,
    MobilePc,
    Makeup,
    Art,
    Offices,
    JewerlyAccessories,
    FlowersPlants,
    Clinic,
    Weddings,
    Library,
    Hookah,
    TikTak,
    SkinCare,
    VegetablesFruits,
    CamerasAndSatellites,
    Massage
};
