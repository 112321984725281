import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

console.log("API Base URL:", API_BASE_URL);

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

console.log('API Base URL:', API_BASE_URL);

// Function to fetch categories from the backend server
export const fetchCategories = async () => {
  try {
    const response = await axiosInstance.get('/categories/getAllCategories');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch categories:', error.response ? error.response.data : error.message);
    throw new Error('Failed to fetch categories');
  }
};

// Function to fetch a category by its ID from the backend server
export const fetchCategoryById = async (categoryId) => {
  try {
    const response = await axiosInstance.get(`/categories/${categoryId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch category:', error.response ? error.response.data : error.message);
    throw new Error('Failed to fetch category');
  }
};

// Function to fetch businesses by a specific subCategoryTitle from the backend server
export const fetchBusinessBySubCategoryTitle = async (subCategoryTitle) => {
  try {
    const response = await axiosInstance.get(`/businesses/getAllBusinessesBySubCategory?subCategoryTitle=${encodeURIComponent(subCategoryTitle)}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch businesses:', error.response ? error.response.data : error.message);
    throw new Error('Failed to fetch businesses');
  }
};

// Function to fetch businesses by a category title from the backend server
export const fetchBusinessByCategoryTitle = async (categoryTitle) => {
  try {
    const response = await axiosInstance.get(`/businesses/getAllBusinessesByCategory?categoryTitle=${encodeURIComponent(categoryTitle)}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch businesses:', error.response ? error.response.data : error.message);
    throw new Error('Failed to fetch businesses');
  }
};

// Function to fetch full details of a business by its ID from the backend server
export const fetchBusinessById = async (id) => {
  try {
    const response = await axiosInstance.get(`/businesses/${id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch business details:', error.response ? error.response.data : error.message);
    throw new Error('Failed to fetch business details');
  }
};

export const updateBusinessById = async (id, business) => {
  try {
    const response = await axiosInstance.put(`/businesses/${id}`, business);
    return response.data;
  } catch (error) {
    console.error('Failed to update business details:', error.response ? error.response.data : error.message);
    throw new Error('Failed to update business details');
  }
};


export const sendVerificationCode = async (phoneNumber) => {
  try {
    const response = await axiosInstance.post('/businesses/send-verification-code', { phoneNumber });
    return response.data;
  } catch (error) {
    console.error('Failed to send verification code:', error.response ? error.response.data : error.message);
    throw new Error('Failed to send verification code');
  }
};

export const verifyCode = async (phoneNumber, verificationCode) => {
  try {
    const response = await axiosInstance.post('/businesses/verify-code', { phoneNumber, verificationCode });
    return response.data;
  } catch (error) {
    console.error('Failed to verify code:', error.response ? error.response.data : error.message);
    throw new Error('Failed to verify code');
  }
};